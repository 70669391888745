import { useRef, useState } from 'react';
import { AdContainer, AdContainerSize, createComponent, If, IntrinsicProps } from 'react-commons';
import { AdUnitType, usePlaywireAdUnit, usePlaywireIdRef } from '@/components/playwire/Playwire';

import style from './index.module.scss';
import AdFallbackBanner from '@/components/AdFallbackBanner';

interface PlaywireUnitProps extends IntrinsicProps {
  unit: AdUnitType
  useAdFallback?: boolean
  adContainerSize?: AdContainerSize
}

export default createComponent<PlaywireUnitProps>('PlaywireUnitWrapper', { style }, function PlaywireUnitWrapper ({}, props) {
  const [ showAdFallback, setShowAdFallback ] = useState(false);

  return (
    <>
      {
        If(props.useAdFallback, () => (
          <AdContainer 
            className='PlaywireAside__AdContainer'
            size={AdContainerSize.MOBILE_BANNER} 
            onAdblockDetected={() => {
              setShowAdFallback(true);
            }}
          />
        )).EndIf()
      }
      {
        If(props.useAdFallback && showAdFallback, () => (
          <AdFallbackBanner />
        )).Else(() => (
          <PlaywireUnit {...props} />
        )).EndIf()
      }
    </>
  );
});

const PlaywireUnit = createComponent<PlaywireUnitProps>('PlaywireUnit', { style }, function PlaywireUnit ({}, props) {
  const ref = useRef<HTMLDivElement>(null);
  const idRef = usePlaywireIdRef();

  usePlaywireAdUnit(ref, { type: props.unit, selectorId: idRef.current });

  return (
    <div ref={ref} className='PlaywireUnit'>
      <div id={idRef.current} />
    </div>
  );
});
