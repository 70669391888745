import { AdContainer, AdContainerSize, createComponent, If, IntrinsicProps } from 'react-commons';
import { useState } from 'react';
import AdFallback from '@/components/AdFallback';

import style from './index.module.scss';

interface PlaywireAsideProps extends IntrinsicProps {
  useAdFallback?: boolean
  useVideoPlaceholder?: boolean
  stickToLeft?: boolean
}

const classStates = [
  'stickToLeft'
];

export default createComponent<PlaywireAsideProps>('PlaywireAside', { classStates, style }, function PlaywireAside ({ className }, props) {
  const [ showAdFallback, setShowAdFallback ] = useState(false);

  return (
    <>
      {
        If(props.useAdFallback, () => (
          <AdContainer 
            className='PlaywireAside__AdContainer'
            size={AdContainerSize.MOBILE_BANNER} 
            onAdblockDetected={() => {
              setShowAdFallback(true);
            }}
          />
        )).EndIf()
      }
      <div className={className}>
        {
          If(props.useAdFallback && showAdFallback, () => (
            <AdFallback size={AdContainerSize.MEDIUM_RECT} style={{ marginTop: '0' }} />
          )).Else(() => (
            <>{props.children}</>
          )).EndIf()
        }
        {
          If(props.useVideoPlaceholder, () => (
            <div />
          )).EndIf()
        }
      </div>
    </>
  );
});
