import { ButtonLink, Flex, IntrinsicProps, Title, createComponent } from 'react-commons';

import style from './index.module.scss';
import { CaptureClickEvent, CaptureClickEventContext } from '@/lib/util/captureClickEvent';
import { useContext } from 'react';
import Link from 'next/link';

export interface AdFallbackBannerProps extends IntrinsicProps {
}

export default createComponent('AdFallbackBanner', { style }, ({ className }, props: AdFallbackBannerProps) => {
  const captureClickEventCtx = useContext(CaptureClickEventContext);

  return (
    <Flex alignCenter justifyCenter className={className} style={props.style}>
      <img src='/images/adFallbackBg.png' alt='Ad Fallback Background' aria-hidden />
      <Flex alignCenter justifyCenter directionColumn>
        <Title className='--textCenter'>Ads keep Shockwave free to play.</Title>
        <p className='--textCenter'>They support our efforts to create content and improve the site. You can also support us with a Shockwave Unlimited Subscription.</p>
      </Flex>
      <Flex alignCenter directionColumn>
        <CaptureClickEvent
          action='upsell'
          location='ad_upsell'
          properties={() => ({
            tag_name: 'upsell_adblock_nag',
            title: captureClickEventCtx.settings.gameTitle ?? '',
            id: captureClickEventCtx.settings.gameId ?? '',
          })}
          href='/unlimited'
        >
          <ButtonLink 
            large secondary rounded 
            href='/unlimited' 
            className='upsell_adblock_nag'
          >
            Subscribe
          </ButtonLink>
        </CaptureClickEvent>
      </Flex>
    </Flex>
  );
});
