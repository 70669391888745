import { App, If, IntrinsicProps, Layout } from 'react-commons';
import { createComponent } from 'react-commons';
import { CaptureClickEventContext } from '@/lib/util/captureClickEvent';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import AppFooter from '@/components/AppFooter';
import DailyAppHeader from '@/components/DailyAppHeader';
import OrientationOverlay from '@/components/OrientationOverlay';

import style from './index.module.scss';
import PlaywireAside from '@/components/playwire/PlaywireAside';
import PlaywireUnit from '@/components/playwire/PlaywireUnit';
import PlaywireCornerVideo from '@/components/playwire/PlaywireCornerVideo';
import PromoAppBanner from '@/components/PromoAppBanner';
import ModernAppFooter from '@/components/ModernAppFooter';

interface FluidLayoutProps extends IntrinsicProps {
  restrictHeight?: boolean
  pageName?: string
  pageEventSettings?: any
  classicColumnWidth?: boolean
  useAdColumn?: boolean
  usePromoBanner?: boolean
}

const classStates = [
  'restrictHeight',
  'classicColumnWidth',
];

export default createComponent<FluidLayoutProps>('FluidLayout', { style, classStates }, function FluidLayout ({ className, slots }, props) {
  const [ authState ] = useAuthStore();
  
  return (
    <CaptureClickEventContext.Provider value={{
      page: props.pageName,
      settings: props.pageEventSettings || {},
    }}>
      <Layout className={className}>
        <App.Header>
          {
            If(props.usePromoBanner, () => (
              <PromoAppBanner 
                eventAction='upsell_sale'
                eventTagName='upsell_sale_index_banner'
                useResize
              />
            )).EndIf()
          }
          {
            If(slots.appHeader, () => (slots.appHeader))
              .Else(() => (<DailyAppHeader />))
              .EndIf()
          }
        </App.Header>

        <App.Main>
          {
            If(props.useAdColumn && !authState.user?.isPremiumUser, () => (
              <aside className='FluidLayout__Aside'>
                <PlaywireAside useAdFallback useVideoPlaceholder>
                  <PlaywireUnit unit='med_rect_atf' />
                </PlaywireAside>
              </aside>
            )).EndIf()
          }
          <div className='FluidLayout__Content'>
            {slots.defaultSlot}
            {
              If(!authState.user?.isPremiumUser, () => (
                <PlaywireCornerVideo />
              )).EndIf()
            }
          </div>
          {
            If(props.useAdColumn && !authState.user?.isPremiumUser, () => (
              <aside className='FluidLayout__Aside'>
                <PlaywireAside stickToLeft>
                  <PlaywireUnit unit='med_rect_btf' />
                  <PlaywireUnit unit='med_rect_btf' />
                </PlaywireAside>
              </aside>
            )).EndIf()
          }
        </App.Main>

        {
          If(props.classicColumnWidth, () => (
            <App.Footer>
              <AppFooter />
            </App.Footer>
          )).Else(() => (
            <App.Footer>
              <ModernAppFooter 
                useAdColumn={props.useAdColumn && !authState.user?.isPremiumUser} 
              />
            </App.Footer>
          )).EndIf()
        }

        <OrientationOverlay />
      </Layout>
    </CaptureClickEventContext.Provider>
  );
});
